<template>
  <div class="p-fluid">
    <form @submit.prevent="onLogin">
      <div class="p-field">
        <label for="username">{{ $t("login.field_username") }}</label>
        <InputText
          id="username"
          v-model.trim="username"
          aria-label="username"
          aria-describedby="username-error"
          :aria-placeholder="translation.usernamePlaceholder"
          :placeholder="translation.usernamePlaceholder"
          :class="{ 'p-invalid': !!errors['username'] }"
          :disabled="isRequesting"
        />
        <small
          v-if="!!errors['username']"
          id="username-error"
          class="p-error"
          role="alert"
          aria-live="polite"
          >{{ $t("login.field_username_error") }}</small
        >
      </div>
      <div class="p-field">
        <label for="password">{{ $t("login.field_password") }}</label>
        <Password
          id="password"
          v-model="password"
          aria-label="password"
          :aria-placeholder="translation.passwordPlaceholder"
          :placeholder="translation.passwordPlaceholder"
          :disabled="isRequesting"
          aria-describedby="password-error"
          toggle-mask
          :feedback="false"
        />
        <small
          v-if="!!errors['password']"
          id="password-error"
          class="p-error"
          role="alert"
          aria-live="polite"
          >{{ $t("login.field_password_error") }}</small
        >
      </div>
      <Button
        class="p-mt-4 p-button-lg"
        type="submit"
        :label="translation.loginLabel"
        :disabled="isRequesting"
      />
    </form>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../config";
import { assertEmail, assertEmpty } from "../service/assert";
import { USER_LOGIN } from "../store/modules/auth/auth.actions.type";
import { GET_FULL_NAME } from "../store/modules/auth/auth.getters.type";
import { GET_LOADING_STATUS } from "../store/modules/global/global.getters.type";

export default {
  name: "LoginForm",
  components: {
    Button,
    InputText,
    Password,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { t } = useI18n();
    const isRequesting = computed(() => store.getters[GET_LOADING_STATUS]);

    const username = ref("");
    const password = ref("");
    const errors = ref([]);
    const translation = {
      loginLabel: t("login.button_login"),
      usernamePlaceholder: t("login.field_username_placeholder"),
      passwordPlaceholder: t("login.field_password_placeholder"),
    };

    async function onLogin() {
      if (validateInput()) {
        await store.dispatch(USER_LOGIN, {
          username: username.value,
          password: password.value,
        });
        await router.push({ name: "dashboard" });
        const name = store.getters[GET_FULL_NAME];
        toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("login.toast_success", { msg: name }),
          life: TOAST_LIFE,
        });
      }
    }

    function validateInput() {
      errors.value = [];
      if (!assertEmail(username.value)) {
        errors.value["username"] = true;
      }
      if (assertEmpty(password.value)) {
        errors.value["password"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    return {
      onLogin,
      isRequesting,
      username,
      password,
      errors,
      translation,
    };
  },
};
</script>
