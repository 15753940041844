<template>
  <the-layout :title="title">
    <form-login />
  </the-layout>
</template>

<script>
import { useI18n } from "vue-i18n";

import FormLogin from "../components/FormLogin";
import TheLayout from "../components/TheLayout";

export default {
  name: "LoginView",
  components: {
    FormLogin,
    TheLayout,
  },
  setup() {
    const { t } = useI18n();
    return {
      title: t("login.title"),
    };
  },
};
</script>
